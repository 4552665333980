import dayjs from 'dayjs';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import { useTranslation } from 'react-i18next';
import DatePicker from 'src/shared/components/datePicker';
import { useState } from 'react';
import { Box, FormControlLabel, Switch } from '@mui/material';
import PurchaseReport from '../components/purchaseReport';
import SellingReport from '../components/sellingReport';
import RepairReport from '../components/repairReport';
import DisposeReport from '../components/disposeReport';
import EntranceReport from '../components/entranceReport';

const ResultReport = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(dayjs().startOf('month').toDate());
  const [endDate, setEndDate] = useState(dayjs().endOf('month').toDate());
  const [isChecked, setIsChecked] = useState(false);

  return (
    <PageTemplate
      title={t('resultReport')}
      subtitle=''
      hideButton
      fullWidth
      content={
        <>
          <Box
            display='flex'
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            sx={{ mt: { xs: 1, md: 0 }, ml: { md: 2 } }}
          >
            <DatePicker
              startDate={startDate}
              onChangeStart={(date) => setStartDate(date)}
              endDate={endDate}
              onChangeEnd={(date) => setEndDate(date)}
              disabled={isChecked}
            />
            <FormControlLabel
              label={t('showRegistrationsFromAllDates')}
              labelPlacement='start'
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: 1,
                mx: 0,
                ml: { md: 1 },
              }}
              control={<Switch checked={isChecked} onChange={() => setIsChecked(!isChecked)} color='primary' />}
            />
          </Box>
          {process.env.REACT_APP_USE_PURCHASE === 'true' && (
            <PurchaseReport startDate={startDate} endDate={endDate} showAllDates={isChecked} />
          )}
          {process.env.REACT_APP_USE_SELLING === 'true' && (
            <SellingReport startDate={startDate} endDate={endDate} showAllDates={isChecked} />
          )}
          {process.env.REACT_APP_USE_REPAIR === 'true' && (
            <RepairReport startDate={startDate} endDate={endDate} showAllDates={isChecked} />
          )}
          {process.env.REACT_APP_USE_DISPOSE === 'true' && (
            <DisposeReport startDate={startDate} endDate={endDate} showAllDates={isChecked} />
          )}
          {process.env.REACT_APP_USE_ENTRANCE === 'true' && (
            <EntranceReport startDate={startDate} endDate={endDate} showAllDates={isChecked} />
          )}
        </>
      }
      onClickButton={() => ConsoleHelper.log('Fortsätt?')}
    />
  );
};

export default ResultReport;
