import { Grid } from '@mui/material';
import BoxButtonIconTitle from 'src/shared/components/boxButtonIconTitle';
import IBoxButtonOption from '../models/buttonOptions';

type GridOfBoxButtonsProps = {
  items: IBoxButtonOption[];
  onClickOption: (arg: IBoxButtonOption) => void;
  activeItem: IBoxButtonOption | undefined;
};

const GridOfBoxButtons = (props: GridOfBoxButtonsProps) => {
  const { items, onClickOption, activeItem } = props;

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      {items.map((item: IBoxButtonOption) => (
        <BoxButtonIconTitle
          key={item?.ID}
          item={item}
          onClickButton={() => onClickOption(item)}
          isActive={activeItem?.ID === item?.ID}
        />
      ))}
    </Grid>
  );
};

export default GridOfBoxButtons;
