import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Selling } from 'src/services/selling.service';
import { Colors } from 'src/shared/styles/colors';
import IconCheckmarkPNG from 'src/assets/checkmark@2x.png';

type SellingConfirmedProps = {
  data: Selling;
  showConfirmed: boolean;
  onPressCreateAgain: () => void;
};
const SellingConfirmed = (props: SellingConfirmedProps) => {
  const { data, showConfirmed, onPressCreateAgain } = props;
  const { t } = useTranslation();

  return (
    <Box
      mt={2}
      sx={{
        display: showConfirmed ? 'flex' : 'none',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box display='flex' flexDirection='column' alignItems='center' my={2}>
        <Typography variant='h4'>{t('nice')}</Typography>
        <Typography fontWeight={700} flex={4} mt={1}>
          {t('yourSelling{title}IsRegistered', { title: data.title })}
        </Typography>
      </Box>
      <Box>
        <img src={IconCheckmarkPNG} height='115px' width='115px' alt='submit-confirmed-icon' />
      </Box>
      <Box>
        <Button
          variant='text'
          onClick={onPressCreateAgain}
          fullWidth
          sx={{
            fontSize: 16,
            height: '50px',
            '&.Mui-disabled': {
              background: Colors.GREEN_OPACITY,
            },
          }}
        >
          {t('createNewRegistration')}
        </Button>
        <Typography color={Colors.STEEL}> {t('withSameSettings')}</Typography>
      </Box>
    </Box>
  );
};

export default SellingConfirmed;
