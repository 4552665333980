import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from 'src/shared/routes/routesEnum';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import IconCartPNG from 'src/assets/cart@2x.png';
import IconDollarPNG from 'src/assets/dollar@2x.png';
import IconRepairPNG from 'src/assets/repair@2x.png';
import IconTrashPNG from 'src/assets/trash@2x.png';
import { useState } from 'react';
import GridOfBoxButtons from 'src/shared/components/gridOfBoxButtons';
import IBoxButtonOption from 'src/shared/models/buttonOptions';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';

const RegisterPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [type, setType] = useState<IBoxButtonOption | undefined>(undefined);

  // Hämta översättningsnyckeln från process.env
  const translationKeyDispose = process.env.REACT_APP_DISPOSE_KEY;

  const onPressContinue = (option: IBoxButtonOption) => {
    setType(option);
    navigate(option.route);
  };

  // TODO: Enum för category types
  // Type 1 = Purchase
  // Type 2 = Selling
  // Type 3 = Repair
  // Type 4 = Dispose
  const registerPageTypes = [
    {
      ID: 1,
      title: t('registerType.purchased'),
      iconSource: IconCartPNG,
      route: RoutesEnum.RegisterPurchase,
      disabled: process.env.REACT_APP_USE_PURCHASE === 'false',
    },
    {
      ID: 2,
      title: t('registerType.sold'),
      iconSource: IconDollarPNG,
      route: RoutesEnum.RegisterSelling,
      disabled: process.env.REACT_APP_USE_SELLING === 'false',
    },
    {
      ID: 3,
      title: t('registerType.repaired'),
      iconSource: IconRepairPNG,
      route: RoutesEnum.RegisterRepair,
      disabled: process.env.REACT_APP_USE_REPAIR === 'false',
    },
    {
      ID: 4,
      title: t(`registerType.${translationKeyDispose}`),
      iconSource: IconTrashPNG,
      route: RoutesEnum.RegisterDisposed,
      disabled: process.env.REACT_APP_USE_DISPOSE === 'false',
    },
  ];

  return (
    <PageTemplate
      title={t('whatDoYouWantToRegister')}
      subtitle={t('purchasedSoldRepairedOrTrashed')}
      content={
        <Box>
          <GridOfBoxButtons
            items={registerPageTypes}
            activeItem={type}
            onClickOption={(option) => onPressContinue(option)}
          />
        </Box>
      }
      onClickButton={() => ConsoleHelper.log('Hidden button')}
      hideButton
      isButtonDisabled={!type}
    />
  );
};

export default RegisterPage;
