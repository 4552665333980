import { useEffect, useState } from 'react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { Box, Typography } from '@mui/material';
import { Colors } from 'src/shared/styles/colors';
import { useTranslation } from 'react-i18next';
import { GenericDialog } from 'src/shared/components/genericDialog';
import { deleteDispose, Dispose, streamDisposals } from 'src/services/dispose.service';
import { DisposeDataGrid } from './disposeDataGrid';
import { RenderPieChartSubCategory } from './disposePieChart';
import Co2SummaryDispose from './co2SummaryDispose';

type DisposeReportProps = {
  startDate: Date;
  endDate: Date;
  showAllDates: boolean;
};
const DisposeReport = (props: DisposeReportProps) => {
  const { startDate, endDate, showAllDates } = props;
  const { t } = useTranslation();
  const [data, setData] = useState<QueryDocumentSnapshot<Dispose>[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedID, setSelectedID] = useState<string | null>(null);

  // Hämta översättningsnyckeln från process.env
  const translationKeyDispose = process.env.REACT_APP_DISPOSE_KEY;

  useEffect(() => {
    const unsubscribe = streamDisposals(
      (querySnapshot) => {
        setData(querySnapshot.docs);
      },
      (error) => ConsoleHelper.log(error.message),
      showAllDates ? undefined : startDate,
      showAllDates ? undefined : endDate,
    );
    return unsubscribe;
  }, [endDate, startDate, showAllDates]);

  const onDeleteClicked = async (id: string) => {
    try {
      const res = await deleteDispose(id);
      ConsoleHelper.log('res deleting', res);
      setSelectedID(null);
    } catch (error) {
      ConsoleHelper.log('error deleting', error);
      setSelectedID(null);
    }
  };

  const handleOnDelete = () => {
    ConsoleHelper.log('Delete item');
    if (selectedID) {
      onDeleteClicked(selectedID);
      setModalOpen(false);
    }
  };
  const disposeData = data.map((doc: QueryDocumentSnapshot<Dispose>) => doc.data() as Dispose);

  return (
    <Box mb={3}>
      <Typography variant='h6' sx={{ color: Colors.STEEL, mb: 1 }}>
        {t('report.registered')} {t(`report.${translationKeyDispose}`)}
      </Typography>
      {data?.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}>
          <RenderPieChartSubCategory data={data} />
        </Box>
      )}
      <Co2SummaryDispose disposals={disposeData} />

      <DisposeDataGrid
        data={data}
        onClickDelete={(id: string) => {
          setSelectedID(id);
          setModalOpen(true);
        }}
      />
      <GenericDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleOnDelete}
        dialogContent={
          <Box>
            <Typography>{t('confirmDeleteRegistration')}</Typography>
          </Box>
        }
      />
    </Box>
  );
};

export default DisposeReport;
