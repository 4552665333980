import {
  // FieldValue,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import { Box, Button } from '@mui/material';
import { Purchase } from 'src/services/purchase.service';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PurchaseSchema } from 'src/shared/models/purchaseSchema';
import dayjs from 'dayjs';
import { ControlledTextInput } from 'src/shared/components/controlledTextInput';
import ControlledSwitch from 'src/shared/components/controlledSwitch';
import ControlledDatePicker from 'src/shared/components/controlledDatePicker';
import { Colors } from 'src/shared/styles/colors';
import ControlledSelectGrouped from 'src/shared/components/controlledSelectGrouped';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { useEffect, useState } from 'react';
import { Category, getCategories } from 'src/services/category.service';
import { appConfig } from 'src/firebaseConf';

type PurchaseFormProps = {
  setPurchaseData: (arg: Purchase) => void;
  setImageData: (arg: string) => void;
  showForm: boolean;
};

const PurchaseForm = (props: PurchaseFormProps) => {
  const { setPurchaseData, setImageData, showForm } = props;
  const { t } = useTranslation();
  const [categories, setCategories] = useState<Category[]>([]);
  const [imageDataUrl, setImageDataUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await getCategories(undefined, [1]);
      setCategories(res);
    };
    fetchCategories();
  }, []);

  const formMethods = useForm({
    resolver: yupResolver(PurchaseSchema()),
    defaultValues: {
      used: false,
      date: dayjs().toDate(),
      category: '',
      title: '',
      description: '',
      price: '',
      // unit: 'st', // Tas från category istället
      quantity: 1,
      createdBy: appConfig.companyId,
      //   created: serverTimestamp() as Timestamp,
    },
  });

  const { handleSubmit, watch } = formMethods;

  // TODO: Fix any
  const onPressSubmit = async (x: any) => {
    ConsoleHelper.log('x', x);
    const submitdata: Purchase = {
      companyId: appConfig.companyId,
      used: x.used,
      date: new Date(x.date),
      category: x.category,
      categoryParent: x.category.categoryParentName,
      title: x.title,
      description: x.description,
      price: x.price ?? 0,
      quantity: x.quantity,
      createdBy: appConfig.companyId,
      created: serverTimestamp() as Timestamp,
    };
    ConsoleHelper.log('submitdata', submitdata);
    setPurchaseData(submitdata); // Sätter datat i state på mammasidan
    if (imageDataUrl) setImageData(imageDataUrl); // Sätter state för bildsträng på mammasidan
  };

  const categoryValue = watch('category');
  const parsedCategory = categoryValue ? JSON.parse(categoryValue) : undefined;

  return (
    <Box
      id='form-container'
      mt={2}
      sx={{
        display: showForm ? 'flex' : 'none',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <FormProvider {...formMethods}>
        <form
          id='form'
          onSubmit={handleSubmit(onPressSubmit)}
          style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
        >
          <ControlledSwitch name='used' label={t('used')} />
          <ControlledDatePicker name='date' label={t('date')} />
          <ControlledSelectGrouped name='category' label={t('category')} options={categories} />
          <ControlledTextInput name='title' label={t('productTitle')} />
          <ControlledTextInput name='description' label={t('description')} />
          <ControlledTextInput
            name='quantity'
            label={`${t('quantity')} (${parsedCategory ? parsedCategory.unit : 'st'})`}
            type='number'
          />
          <ControlledTextInput
            name='price'
            label={`${t('price')}/${parsedCategory ? parsedCategory.unit : 'st'}`}
            type='number'
          />
          {process.env.REACT_APP_USE_IMAGES === 'true' && (
            <Box py={2}>
              <input
                type='file'
                accept='image/*'
                onChange={async (event) => {
                  if (event.target.files && event.target.files[0]) {
                    const file = event.target.files[0];
                    const reader = new FileReader();

                    reader.onloadend = () => {
                      setImageDataUrl(reader.result as string);
                    };
                    reader.readAsDataURL(file);
                  }
                }}
              />
            </Box>
          )}
          <Box
            id='submit-container'
            sx={{
              display: 'flex',
              flex: 1,
              alignItems: 'flex-end',
              width: '100%',
            }}
          >
            <Button
              type='submit'
              variant='contained'
              fullWidth
              sx={{
                height: '50px',
                '&.Mui-disabled': {
                  background: Colors.GREEN_OPACITY,
                },
              }}
            >
              {t('next')}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default PurchaseForm;
