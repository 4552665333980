import { Box, Typography, Button, Grid } from '@mui/material';
import { Colors } from 'src/shared/styles/colors';
import IBoxButtonOption from '../models/buttonOptions';

type BoxButtonIconTitleProps = {
  onClickButton: () => void;
  item: IBoxButtonOption;
  isActive?: boolean;
};
const BoxButtonIconTitle = (props: BoxButtonIconTitleProps) => {
  const { onClickButton, item, isActive } = props;

  return (
    <Grid item xs={12} sm={6}>
      <Button
        variant='outlined'
        onClick={onClickButton}
        disabled={item?.disabled}
        // component={Link}
        // to={goToRoute}
        sx={{
          width: '100%',
          borderColor: isActive ? Colors.GREEN : Colors.GRAY_LIGHT,
          borderWidth: 2,
          borderRadius: '1.5rem',
          height: '140px',
          '&:hover': {
            borderWidth: 2,
            borderColor: Colors.GREEN,
          },
          opacity: item?.disabled ? 0.4 : 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderColor: Colors.STEEL,
            borderRadius: 2,
            bgcolor: Colors.WHITE,
          }}
        >
          <img src={item?.iconSource} height='40%' width='40%' alt={item?.title} />
          <Typography color={Colors.BLACK} align='center' pt={2}>
            {item?.title}
          </Typography>
        </Box>
      </Button>
    </Grid>
  );
};

export default BoxButtonIconTitle;

BoxButtonIconTitle.defaultProps = {
  isActive: false,
};
