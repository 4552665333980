import { Box, Tooltip } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { QueryDocumentSnapshot, Timestamp } from 'firebase/firestore';
import { Dispose } from 'src/services/dispose.service';
import dayjs from 'dayjs';
import { DeleteButton } from '../../../shared/components/reportItems';
import { ImagePopover } from './imagePopover';

export const DisposeDataGrid = ({ data, onClickDelete }: any) => {
  const disposals = data.map((doc: QueryDocumentSnapshot<Dispose>) => doc);
  const columns = [
    {
      field: 'categoryParent',
      headerName: 'Huvudkategori',
      flex: 0.2,
      renderCell: (params: any) => (
        <Tooltip title={params.row.categoryParent}>
          <span>{params.row.categoryParent}</span>
        </Tooltip>
      ),
    },
    {
      field: 'categoryName',
      headerName: 'Kategori',
      flex: 0.25,
      renderCell: (params: any) => (
        <Tooltip title={params.row.categoryName}>
          <span>{params.row.categoryName}</span>
        </Tooltip>
      ),
    },
    {
      field: 'image',
      headerName: 'Bild',
      hide: process.env.REACT_APP_USE_IMAGES === 'false',
      flex: 0.15,
      renderCell: (params: any) => {
        return (
          <Box>
            {params.row.image && (
              <ImagePopover image={params.row.image} title={params.row.title}>
                <img src={params.row.image} alt={params.row.title} style={{ maxWidth: '100%', maxHeight: '100%' }} />
              </ImagePopover>
            )}
          </Box>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Datum',
      flex: 0.15,
      renderCell: (params: any) => (
        <Tooltip title={params.row.date}>
          <span>{params.row.date}</span>
        </Tooltip>
      ),
    },
    {
      field: 'title',
      headerName: 'Produkttitel',
      flex: 0.25,
      renderCell: (params: any) => (
        <Tooltip title={params.row.title}>
          <span>{params.row.title}</span>
        </Tooltip>
      ),
    },
    {
      field: 'description',
      headerName: 'Beskrivning',
      sortable: false,
      flex: 0.25,
      hide: process.env.REACT_APP_USE_DESCRIPTION === 'false',
      renderCell: (params: any) => (
        <Tooltip title={params.row.description}>
          <span>{params.row.description}</span>
        </Tooltip>
      ),
    },
    {
      field: 'quantity',
      headerName: 'Antal',
      sortable: false,
      type: 'number',
      flex: 0.1,
    },
    {
      field: 'unit',
      headerName: ' ',
      sortable: false,
      width: 30,
    },
    // {
    //   field: 'sum',
    //   headerName: 'Summa',
    //   type: 'number',
    //   flex: 0.1,
    // },
    {
      field: 'id',
      headerName: '',
      sortable: false,
      width: 30,
      renderCell: (params: any) => {
        const { id } = params.row;
        return <DeleteButton onClick={() => onClickDelete(id)} />;
      },
    },
  ];

  const rows = disposals.map((doc: any) => {
    const convertTimestampToDate = (timestamp: Timestamp | any): Date | any => {
      return timestamp instanceof Timestamp
        ? new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate()
        : timestamp;
    };
    const dateCreated = convertTimestampToDate(doc.data().date);

    return {
      id: doc.id,
      date: dayjs(dateCreated).format('YYYY-MM-DD'),
      categoryParent: doc.data().categoryParent,
      category: doc.data().category,
      categoryName: doc.data().category.name,
      title: doc.data().title,
      description: doc.data().description,
      quantity: doc.data().quantity,
      unit: doc.data().category.unit,
      sum: doc.data().price * doc.data().quantity,
      image: doc.data().image,
    };
  });

  return (
    <Box sx={{ height: 400 }}>
      <DataGrid
        // autoHeight
        rows={rows}
        columns={columns}
        pageSize={100}
        components={{ Toolbar: GridToolbar }}
        // sx={{ maxHeight: 400 }}
      />
    </Box>
  );
};
