import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RoutesEnum } from 'src/shared/routes/routesEnum';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import IconRegisterPNG from 'src/assets/register@2x.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GridOfBoxButtons from 'src/shared/components/gridOfBoxButtons';
import IBoxButtonOption from 'src/shared/models/buttonOptions';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';

const AdminPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [type, setType] = useState<IBoxButtonOption | undefined>(undefined);

  const onPressContinue = (option: IBoxButtonOption) => {
    setType(option);
    navigate(option.route);
  };

  const adminPageTypes = [
    {
      ID: 1,
      title: t('handleCategories'),
      iconSource: IconRegisterPNG,
      route: RoutesEnum.AdminCategories,
      disabled: false,
    },
  ];

  return (
    <PageTemplate
      showBackArrow
      title={t('Administration')}
      subtitle={t('Ändringar av nedan uppdateras för alla användare')}
      content={
        <Box>
          <GridOfBoxButtons
            items={adminPageTypes}
            activeItem={type}
            onClickOption={(option) => onPressContinue(option)}
          />
        </Box>
      }
      onClickButton={() => ConsoleHelper.log('Hidden button')}
      hideButton
      isButtonDisabled={!type}
    />
  );
};

export default AdminPage;
