import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { Box } from '@mui/material';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from 'src/shared/routes/routesEnum';
import { Selling } from 'src/services/selling.service';
import SellingForm from './sellingForm';
import SellingSummary from './sellingSummary';
import SellingConfirmed from './sellingConfirmed';

const RegisterSelling = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [step, setStep] = useState<number>(1); // Step 1 = Form, Step 2 = Summary, Step 3 = Confirmed
  const [data, setData] = useState<Selling>();

  const setSellingData = (x: Selling) => {
    ConsoleHelper.log('DATA', x);
    setData(x);
    setStep(2);
  };
  return (
    <PageTemplate
      title={step !== 3 ? t('registerPageTitle.sold') : ''}
      subtitle={step === 1 ? t('typeSubtitle.enterInformationSold') : ''}
      showBackArrow={step !== 3}
      onClickBack={step === 2 ? () => setStep(1) : undefined}
      hideButton={step !== 3}
      buttonTitle={t('close') ?? 'Stäng'}
      onClickButton={() => navigate(RoutesEnum.Default)}
      content={
        <Box id='content-container' display='flex' flex={1} flexDirection='column'>
          <SellingForm setSellingData={(x: Selling) => setSellingData(x)} showForm={step === 1} />
          {data && <SellingSummary data={data} showSummary={step === 2} onSubmitSuccess={() => setStep(3)} />}
          {data && <SellingConfirmed data={data} showConfirmed={step === 3} onPressCreateAgain={() => setStep(1)} />}
        </Box>
      }
    />
  );
};

export default RegisterSelling;
