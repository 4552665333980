import { Modal, Box, IconButton, LinearProgress } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';

type ModalTemplateProps = {
  title: JSX.Element;
  content: JSX.Element;
  secondaryAction?: JSX.Element;
  isOpen: boolean;
  modalWidth?: string;
  onClose: () => void;
  isLoading: boolean;
};

const ModalTemplate = (props: ModalTemplateProps) => {
  const { title, content, secondaryAction, isOpen, onClose, modalWidth, isLoading } = props;
  return (
    <Modal open={isOpen} onClose={onClose} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        id='modalScroll'
        sx={{
          width: { xs: 'calc(100vw - 32px)', lg: modalWidth },
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          p: { xs: 1, md: 4 },
          m: 0,
          maxHeight: 'calc(100vh - 32px)',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            p: 0,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton
            aria-label='close-modal'
            onClick={onClose}
            sx={{
              color: 'primary.main',
              p: 0,
              m: 0,
            }}
          >
            <CloseRounded />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            pb: 1,
          }}
        >
          {title}
        </Box>
        <Box sx={{ mt: 1, height: 10, alignItems: 'center', width: '100%' }}>{isLoading && <LinearProgress />}</Box>
        {content}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          {secondaryAction}
        </Box>
      </Box>
    </Modal>
  );
};

ModalTemplate.defaultProps = {
  secondaryAction: undefined,
  modalWidth: '50%',
};

export default ModalTemplate;
