import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { Box } from '@mui/material';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from 'src/shared/routes/routesEnum';
import { Dispose } from 'src/services/dispose.service';
import DisposeForm from './disposeForm';
import DisposeSummary from './disposeSummary';
import DisposeConfirmed from './disposeConfirmed';

const RegisterDisposed = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [step, setStep] = useState<number>(1); // Step 1 = Form, Step 2 = Summary, Step 3 = Confirmed
  const [data, setData] = useState<Dispose>();
  const [imageData, setImageData] = useState<string>('');

  // Hämta översättningsnyckeln från process.env
  const translationKeyDispose = process.env.REACT_APP_DISPOSE_KEY;

  const setDisposeData = (x: Dispose) => {
    ConsoleHelper.log('DATA', x);
    setData(x);
    setStep(2);
  };
  return (
    <PageTemplate
      title={step !== 3 ? t(`registerPageTitle.${translationKeyDispose}`) : ''}
      subtitle={step === 1 ? t(`typeSubtitle.${translationKeyDispose}`) : ''}
      showBackArrow={step !== 3}
      onClickBack={step === 2 ? () => setStep(1) : undefined}
      hideButton={step !== 3}
      buttonTitle={t('close') ?? 'Stäng'}
      onClickButton={() => navigate(RoutesEnum.Default)}
      content={
        <Box id='content-container' display='flex' flex={1} flexDirection='column'>
          <DisposeForm
            setDisposeData={(x: Dispose) => setDisposeData(x)}
            setImageData={(arg: string) => setImageData(arg)}
            showForm={step === 1}
          />
          {data && (
            <DisposeSummary
              data={data}
              imageData={imageData}
              showSummary={step === 2}
              onSubmitSuccess={() => setStep(3)}
            />
          )}
          {data && <DisposeConfirmed data={data} showConfirmed={step === 3} onPressCreateAgain={() => setStep(1)} />}
        </Box>
      }
    />
  );
};

export default RegisterDisposed;
