import * as yup from 'yup';

export const PurchaseSchema = () =>
  yup.object().shape({
    used: yup.boolean().required(),
    date: yup.date().required(),
    category: yup.object().required().default(undefined),
    title: yup.string(),
    description: yup.string().optional(),
    price: yup.number().required().nullable(),
    // unit: yup.string().required(),
    quantity: yup.number().required(),
    createdBy: yup.string().required(),
    // created: yup.string().optional(),
  });

// export type PurchaseType = yup.InferType<typeof PurchaseSchema>;
