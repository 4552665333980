import { Box, IconButton, Typography } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from 'src/shared/styles/colors';

type ButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};
export const DeleteButton = ({ onClick, disabled }: ButtonProps) => {
  return (
    <IconButton aria-label='delete' onClick={onClick} disabled={disabled}>
      <RemoveCircleOutlineIcon color={disabled ? 'disabled' : 'error'} fontSize='small' />
    </IconButton>
  );
};
DeleteButton.defaultProps = {
  disabled: false,
};

export const EditButton = ({ onClick, disabled }: ButtonProps) => {
  return (
    <IconButton aria-label='edit' onClick={onClick} disabled={disabled}>
      <EditIcon color={disabled ? 'disabled' : 'primary'} fontSize='small' />
    </IconButton>
  );
};
EditButton.defaultProps = {
  disabled: false,
};
export const SubmitButton = () => {
  return (
    <IconButton type='submit' aria-label='submit'>
      <DoneIcon color='primary' fontSize='small' />
    </IconButton>
  );
};
export const SaveButton = ({ onClick, disabled }: ButtonProps) => {
  return (
    <IconButton aria-label='save' onClick={onClick} disabled={disabled}>
      <DoneIcon color={disabled ? 'disabled' : 'primary'} fontSize='small' />
    </IconButton>
  );
};
SaveButton.defaultProps = {
  disabled: false,
};
export const CancelButton = ({ onClick, disabled }: ButtonProps) => {
  return (
    <IconButton aria-label='cancel' onClick={onClick} disabled={disabled}>
      <CloseIcon color={disabled ? 'disabled' : 'action'} fontSize='small' />
    </IconButton>
  );
};
CancelButton.defaultProps = {
  disabled: false,
};

export const NoDataComponent = () => {
  return (
    <Box
      sx={{
        height: 250,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography color={Colors.STEEL}>Ingen data registrerad</Typography>
    </Box>
  );
};
