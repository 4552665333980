export const CategoryGroups = [
  {
    ID: 1,
    value: 'Elektronik & elektriska produkter',
    enabled: process.env.REACT_APP_USE_ELECTRONIC === 'true',
  },
  {
    ID: 2,
    value: 'Kontorsutrustning',
    enabled: process.env.REACT_APP_USE_OFFICE_SUPPLY === 'true',
  },
  {
    ID: 3,
    value: 'Förpackningar',
    enabled: process.env.REACT_APP_USE_PACKAGING === 'true',
  },
  {
    ID: 4,
    value: 'Övrigt avfall',
    enabled: process.env.REACT_APP_USE_OTHER_WASTE === 'true',
  },
  {
    ID: 5,
    value: 'Övrigt',
    enabled: process.env.REACT_APP_USE_OTHER === 'true',
  },
].filter((category) => category.enabled);

export const CategoryTypes = [
  {
    ID: 1,
    value: 'Införskaffat',
  },
  {
    ID: 2,
    value: 'Sålt',
  },
  {
    ID: 3,
    value: 'Reparerat',
  },
  {
    ID: 4,
    value: 'Kasserat/Inlämnat',
  },
];
export const Units = [
  {
    ID: 1,
    value: 'st',
  },
  {
    ID: 2,
    value: 'kg',
  },
];
