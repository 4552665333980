/* eslint-disable class-methods-use-this */
import dayjs from 'dayjs';
import {
  //   collection,
  //   setDoc,
  //   updateDoc,
  //   DocumentData,
  //   FieldValue,
  //   arrayUnion,
  deleteDoc,
  doc,
  addDoc,
  serverTimestamp,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
  orderBy,
  FirestoreError,
  QuerySnapshot,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { appConfig, repairCollection } from '../firebaseConf';
import { Category } from './category.service';

export interface Repair {
  companyId: string;
  date: Date;
  category: Category;
  categoryParent: string;
  title: string;
  description: string;
  price: number;
  time?: number;
  quantity: number;
  created?: Timestamp;
  createdBy: string;
}

export const createRepair = (userId: string, item: Repair) => {
  return addDoc(repairCollection, {
    ...item,
    created: serverTimestamp(),
    createdBy: userId,
  });
};

export const getRepair = (id: string) => {
  const documentRef = doc(repairCollection, id);
  return getDoc(documentRef);
};

export const deleteRepair = (id: string) => {
  const documentRef = doc(repairCollection, id);
  return deleteDoc(documentRef);
};

export const getRepairs = () => {
  return getDocs(repairCollection);
};

export const setCompanyIDOnAllRepairs = () => {
  getDocs(repairCollection).then((snapshot) => {
    snapshot.forEach((x) => {
      const docRef = doc(repairCollection, x.id);

      updateDoc(docRef, { companyId: appConfig.companyId })
        .then(() => {
          ConsoleHelper.log(`Document ${x.id} updated successfully.`);
        })
        .catch((error) => {
          ConsoleHelper.error(`Error updating document ${x.id}: ${error}`);
        });
    });
  });
};

export const streamRepairs = (
  snapshot: (snapshot: QuerySnapshot<Repair>) => void,
  error: (error: FirestoreError) => void,
  start?: Date,
  end?: Date,
) => {
  let q = query(repairCollection, orderBy('date', 'desc'));
  q = query(q, where('companyId', '==', appConfig.companyId));
  if (start && end) {
    const startTimestamp = Timestamp.fromDate(dayjs(start).toDate());
    const endTimestamp = Timestamp.fromDate(dayjs(end).toDate());
    q = query(
      repairCollection,
      where('companyId', '==', appConfig.companyId),
      where('date', '>=', startTimestamp),
      where('date', '<=', endTimestamp),
      orderBy('date', 'desc'),
    );
  }

  return onSnapshot(q, { includeMetadataChanges: true }, snapshot, error);
};
