import * as yup from 'yup';

export const CategorySchema = () =>
  yup.object().shape({
    unit: yup.string().required(),
    co2: yup.number().required(),
    type: yup.array().of(yup.string()).required(),
    category: yup.object().required(),
    name: yup.string().required(),
  });
