import { Box, Button, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';
import { RoutesEnum } from 'src/shared/routes/routesEnum';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import IconRegisterPNG from 'src/assets/register@2x.png';
import IconStatsPNG from 'src/assets/stats@2x.png';
import { useNavigate, Link } from 'react-router-dom';
import GridOfBoxButtons from 'src/shared/components/gridOfBoxButtons';
import IBoxButtonOption from 'src/shared/models/buttonOptions';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { useState } from 'react';
import { GenericDialog } from 'src/shared/components/genericDialog';
import { createEntrance } from 'src/services/entrance.service';

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showEntranceDialog, setShowEntranceDialog] = useState(false);

  const onPressContinue = (option: IBoxButtonOption) => {
    if (option.ID === 1 && process.env.REACT_APP_USE_ENTRANCE === 'true') {
      setShowEntranceDialog(true);
    } else {
      navigate(option.route);
    }
  };

  const handleCloseDialog = () => {
    ConsoleHelper.log('LÄGG INTE TILL INPASSERING');
    setShowEntranceDialog(false);
    navigate(RoutesEnum.RegisterDisposed);
  };

  const handleAddEntrance = async () => {
    ConsoleHelper.log('LÄGG TILL INPASSERING');
    try {
      const res = await createEntrance(process.env.REACT_APP_COMPANY_ID ?? '1234');
      ConsoleHelper.log('RES createEntrance::', res);
    } catch (error) {
      ConsoleHelper.log('ERR createEntrance::', error);
    } finally {
      setShowEntranceDialog(false);
      navigate(RoutesEnum.RegisterDisposed);
    }
  };

  const homePageTypes = [
    {
      ID: 1,
      title: process.env.REACT_APP_ONLY_DISPOSE === 'true' ? t('registerDispose') : t('registerData'),
      iconSource: IconRegisterPNG,
      route: process.env.REACT_APP_ONLY_DISPOSE === 'true' ? RoutesEnum.RegisterDisposed : RoutesEnum.Register,
      disabled: false,
    },
    {
      ID: 2,
      title: t('resultReport'),
      iconSource: IconStatsPNG,
      route: RoutesEnum.ResultReport,
      disabled: false,
    },
  ];

  return (
    <PageTemplate
      showBackArrow={false}
      title={t('whatDoYouWantToDo')}
      subtitle={t('registerDataOrSeeReport')}
      content={
        <Box>
          <Button
            sx={{ position: 'absolute', top: 20, right: 20 }}
            component={Link}
            to={RoutesEnum.Admin}
            endIcon={<SettingsIcon />}
          >
            {process.env.REACT_APP_SITE_TITLE}
          </Button>
          <GridOfBoxButtons
            items={homePageTypes}
            activeItem={undefined}
            onClickOption={(option) => onPressContinue(option)}
          />
          <GenericDialog
            open={showEntranceDialog}
            onClose={handleCloseDialog}
            onSave={handleAddEntrance}
            dialogContent={<Typography>{t('registerNewEntrance')}</Typography>}
          />
        </Box>
      }
      onClickButton={() => ConsoleHelper.log('Hidden button')}
      hideButton
      // isButtonDisabled={!type}
    />
  );
};

export default HomePage;
