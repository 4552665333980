/* eslint-disable max-len */
import { Box, Tooltip, Typography } from '@mui/material';
import IconCheckmarkPNG from 'src/assets/checkmark@2x.png';
import { Dispose } from 'src/services/dispose.service';
import { useFetchCategories } from 'src/shared/helpers/useFetchCategories';
// import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { calculateCO2Comparison, EmissionTooltip } from './calculateCo2Comparision';

interface Co2SummaryDisposeProps {
  disposals: Dispose[];
}

const Co2SummaryDispose = ({ disposals }: Co2SummaryDisposeProps) => {
  const { categories } = useFetchCategories();

  const tooltipText =
    'T ex restavfall & kartong räknas som negativ påverkan och drar ner det "sparade" värdet från övrig återvinning';

  // const restavfall = categories.find((cat) => cat.ID === 42);
  // const kartong = categories.find((cat) => cat.ID === 32);
  // Räkna ut hur mycket avfall (minus restavfall ID 42 och kartong ID 32) har sparat Co2/kg
  // const totalCo2SavedWithoutRestAndKartong = disposals.reduce(
  //   (totalCO2, disposal) => {
  //     const category = categories.find(
  //       (cat) => cat.ID === disposal.category.ID,
  //     );
  //     const co2: number = category ? category.co2 : 0;
  //     if (category && (category.ID === 42 || category.ID === 32)) {
  //       return totalCO2;
  //     }
  //     return totalCO2 + co2 * disposal.quantity;
  //   },
  //   0,
  // );
  // Då restavfall och kartong inte "sparar" Co2 måste det räknas bort från de övriga avfallens "sparade" Co2
  // const totalCo2Saved =
  //   totalCo2SavedWithoutRestAndKartong -
  //   (restavfall ? restavfall.co2 : 0) *
  //     disposals
  //       .filter((disposal) => disposal.category.ID === 42)
  //       .reduce((total, disposal) => total + disposal.quantity, 0) -
  //   (kartong ? kartong.co2 : 0) *
  //     disposals
  //       .filter((disposal) => disposal.category.ID === 32)
  //       .reduce((total, disposal) => total + disposal.quantity, 0);

  // Lagt in negativa värden på restavfall och kartong istället
  const totalCo2Saved = disposals.reduce((totalCO2, disposal) => {
    const category = categories.find((cat) => cat.ID === disposal.category.ID);
    const co2: number = category ? category.co2 : 0;
    return totalCO2 + co2 * disposal.quantity;
  }, 0);

  const totalSavedCoToFixed = totalCo2Saved.toFixed(2);

  // Skriv ut ett meddelande beroende på om värdet blir positivt eller negativt
  let summaryString;
  if (totalCo2Saved > 0) {
    summaryString = `Bra jobbat! Ni har under perioden återvunnit och har sparat ca ${totalSavedCoToFixed} kg CO2 genom att återvinna.`;
  } else if (totalCo2Saved < 0) {
    summaryString = `Här har ni potential att förbättras! Försök att återvinna mer så kan ni få ner er miljöpåverkan som blev ca ${
      totalCo2Saved >= 0 ? totalSavedCoToFixed : -totalSavedCoToFixed
    } kg CO2 under denna period.`;
  } else {
    summaryString = null;
  }
  if (!summaryString) return null;

  return (
    <Box display='flex' alignItems='center' justifyContent='center' p={2}>
      <Box sx={{ mr: 1 }}>
        <img src={IconCheckmarkPNG} height='40px' width='40px' alt='summary-icon' />
      </Box>
      <Tooltip title={<Typography fontSize={12}>{tooltipText}</Typography>}>
        <Box>
          <Typography fontStyle='italic'>{summaryString}</Typography>
          <Typography fontStyle='italic'>{calculateCO2Comparison(totalCo2Saved)}</Typography>
        </Box>
      </Tooltip>
      <EmissionTooltip />
    </Box>
  );
};

export default Co2SummaryDispose;
