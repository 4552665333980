import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface IOptions {
  ID: number;
  value: string;
}

type ControlledSelectProps = {
  name: string;
  label: string;
  options: IOptions[];
};

const ControlledSelect = (props: ControlledSelectProps) => {
  const { name, label, options } = props;
  const { t } = useTranslation([]);
  const { control } = useFormContext();
  const { errors } = useFormState();

  let isError = false;
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
  }

  const labelId = `${name}-label`;
  const selectId = `${name}-select`;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl error={isError} fullWidth sx={{ my: 1 }}>
          <InputLabel id={labelId}>{label}</InputLabel>
          <Select
            {...field}
            label={label}
            labelId={labelId}
            id={selectId}
            onChange={(item) => field.onChange(item)}
            value={field.value}
            // multiple
          >
            {options.map((item) => (
              <MenuItem key={item.ID} value={item.value}>
                {t(`${item.value}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default ControlledSelect;
