/* eslint-disable class-methods-use-this */
import dayjs from 'dayjs';
import {
  // collection,
  //   setDoc,
  //   updateDoc,
  //   DocumentData,
  //   FieldValue,
  //   arrayUnion,
  deleteDoc,
  doc,
  addDoc,
  serverTimestamp,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
  orderBy,
  FirestoreError,
  QuerySnapshot,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { ref, uploadString, getDownloadURL } from 'firebase/storage';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { appConfig, disposeCollection, storage } from '../firebaseConf';
import { Category } from './category.service';

export interface Dispose {
  companyId: string;
  date: Date;
  category: Category;
  categoryParent: string;
  title: string;
  description: string;
  quantity: number;
  created?: Timestamp;
  createdBy: string;
  image?: string;
}

const uploadImage = async (imageDataUrl: string) => {
  const imageRef = ref(storage, `images/${Date.now()}.png`); // Or use a unique ID
  await uploadString(imageRef, imageDataUrl, 'data_url');
  const imageUrl = await getDownloadURL(imageRef);

  return imageUrl;
};

export const createDispose = async (userId: string, item: Dispose, imageDataUrl?: string) => {
  const imageUrl = imageDataUrl ? await uploadImage(imageDataUrl) : '';

  return addDoc(disposeCollection, {
    ...item,
    image: imageUrl,

    created: serverTimestamp(),
    createdBy: userId,
  });
};

export const getDispose = (id: string) => {
  const documentRef = doc(disposeCollection, id);
  return getDoc(documentRef);
};

export const deleteDispose = (id: string) => {
  const documentRef = doc(disposeCollection, id);
  return deleteDoc(documentRef);
};

export const getDisposes = () => {
  return getDocs(disposeCollection);
};

export const setCompanyIDOnAllDisposals = () => {
  getDocs(disposeCollection).then((snapshot) => {
    snapshot.forEach((x) => {
      const docRef = doc(disposeCollection, x.id);

      updateDoc(docRef, { companyId: appConfig.companyId })
        .then(() => {
          ConsoleHelper.log(`Document ${x.id} updated successfully.`);
        })
        .catch((error) => {
          ConsoleHelper.error(`Error updating document ${x.id}: ${error}`);
        });
    });
  });
};
export const streamDisposals = (
  snapshot: (snapshot: QuerySnapshot<Dispose>) => void,
  error: (error: FirestoreError) => void,
  start?: Date,
  end?: Date,
) => {
  let q = query(disposeCollection, orderBy('date', 'desc'));
  q = query(q, where('companyId', '==', appConfig.companyId));
  if (start && end) {
    const startTimestamp = Timestamp.fromDate(dayjs(start).toDate());
    const endTimestamp = Timestamp.fromDate(dayjs(end).toDate());
    q = query(
      disposeCollection,
      where('companyId', '==', appConfig.companyId),
      where('date', '>=', startTimestamp),
      where('date', '<=', endTimestamp),
      orderBy('date', 'desc'),
    );
  }

  return onSnapshot(q, { includeMetadataChanges: true }, snapshot, error);
};
