export enum RoutesEnum {
  Default = '/',
  Register = '/register',
  RegisterPurchase = '/register-purchase',
  RegisterSelling = '/register-selling',
  RegisterRepair = '/register-repair',
  RegisterDisposed = '/register-disposed',
  ResultReport = '/result-report',
  Admin = '/admin',
  AdminCategories = '/admin/categories',
  NotFound = '*',
}
