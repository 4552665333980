// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
// import { getAnalytics } from 'firebase/analytics';
import {
  collection,
  CollectionReference,
  DocumentData,
  enableIndexedDbPersistence,
  getFirestore,
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { Category } from './services/category.service';
import { Dispose } from './services/dispose.service';
import { Purchase } from './services/purchase.service';
import { Repair } from './services/repair.service';
import { Selling } from './services/selling.service';
import ConsoleHelper from './shared/helpers/consoleHelper';
import { Entrance } from './services/entrance.service';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDMBW8oeXWVBejzy1yq45zhx-i4aW3Hd00',
  authDomain: 'milav-1674f.firebaseapp.com',
  projectId: 'milav-1674f',
  storageBucket: 'milav-1674f.appspot.com',
  messagingSenderId: '484700873568',
  appId: '1:484700873568:web:a32387af9ec099df657405',
  measurementId: 'G-KETZBCL1C7',
};

export const appConfig = {
  companyId: process.env.REACT_APP_COMPANY_ID ?? 'Milav',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);

// const auth = getAuth(app);
export const db = getFirestore(app);
enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
    ConsoleHelper.warn('Persistance not available across multiple tabs');
  } else if (err.code === 'unimplemented') {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
    ConsoleHelper.warn('Browser does not support offline mode.');
  }
});

// This is just a helper to add the type to the db responses
const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(db, collectionName) as CollectionReference<T>;
};

export const purchaseCollection = createCollection<Purchase>('purchase');

export const sellingCollection = createCollection<Selling>('selling');

export const repairCollection = createCollection<Repair>('repair');

export const disposeCollection = createCollection<Dispose>('dispose');

export const categoryCollection = createCollection<Category>('category');

export const entranceCollection = createCollection<Entrance>('entrance');
