/* eslint-disable class-methods-use-this */
import dayjs from 'dayjs';
import {
  // collection,
  // setDoc,
  // updateDoc,
  // arrayUnion,
  // DocumentData,
  // FieldValue,
  doc,
  deleteDoc,
  addDoc,
  serverTimestamp,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
  orderBy,
  FirestoreError,
  QuerySnapshot,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { ref, uploadString, getDownloadURL } from 'firebase/storage';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { appConfig, purchaseCollection, storage } from '../firebaseConf';
import { Category } from './category.service';

export interface Purchase {
  companyId: string;
  date: Date;
  category: Category;
  categoryParent: string;
  title: string;
  description: string;
  price: number;
  quantity: number;
  // unit: string; // Skickas med på vald kategori istället
  used: boolean;
  created?: Timestamp;
  createdBy: string;
  image?: string;
}

const uploadImage = async (imageDataUrl: string) => {
  const imageRef = ref(storage, `images/${Date.now()}.png`); // Or use a unique ID
  await uploadString(imageRef, imageDataUrl, 'data_url');
  const imageUrl = await getDownloadURL(imageRef);
  ConsoleHelper.log('imageUrl after uploadImage', imageUrl);

  return imageUrl;
};

export const createPurchase = async (userId: string, item: Purchase, imageDataUrl?: string) => {
  const imageUrl = imageDataUrl ? await uploadImage(imageDataUrl) : '';

  return addDoc(purchaseCollection, {
    ...item,
    image: imageUrl,
    created: serverTimestamp(),
    createdBy: userId,
  });
};

export const getPurchase = (id: string) => {
  const documentRef = doc(purchaseCollection, id);
  return getDoc(documentRef);
};

export const deletePurchase = (id: string) => {
  const documentRef = doc(purchaseCollection, id);
  return deleteDoc(documentRef);
};

export const getPurchases = () => {
  return getDocs(purchaseCollection);
};

export const setCompanyIDOnAllPurchases = () => {
  getDocs(purchaseCollection).then((snapshot) => {
    snapshot.forEach((x) => {
      const docRef = doc(purchaseCollection, x.id);

      updateDoc(docRef, { companyId: appConfig.companyId })
        .then(() => {
          ConsoleHelper.log(`Document ${x.id} updated successfully.`);
        })
        .catch((error) => {
          ConsoleHelper.error(`Error updating document ${x.id}: ${error}`);
        });
    });
  });
};

export const streamPurchases = (
  snapshot: (snapshot: QuerySnapshot<Purchase>) => void,
  error: (error: FirestoreError) => void,
  start?: Date,
  end?: Date,
) => {
  let q = query(purchaseCollection, orderBy('date', 'desc'));
  q = query(q, where('companyId', '==', appConfig.companyId));
  if (start && end) {
    const startTimestamp = Timestamp.fromDate(dayjs(start).toDate());
    const endTimestamp = Timestamp.fromDate(dayjs(end).toDate());
    q = query(
      purchaseCollection,
      where('companyId', '==', appConfig.companyId),
      where('date', '>=', startTimestamp),
      where('date', '<=', endTimestamp),
      orderBy('date', 'desc'),
    );
  }
  return onSnapshot(q, { includeMetadataChanges: true }, snapshot, error);
};
