/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
import {
  collection,
  setDoc,
  updateDoc,
  //   DocumentData,
  //   FieldValue,
  //   arrayUnion,
  deleteDoc,
  doc,
  addDoc,
  serverTimestamp,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
  orderBy,
  FirestoreError,
  QuerySnapshot,
  Timestamp,
  Query,
} from 'firebase/firestore';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { appConfig, categoryCollection, db } from '../firebaseConf';

interface MetadataData {
  nextCategoryId: number;
}

export interface Category {
  companyId: string;
  id?: string;
  ID?: number;
  categoryParentID: number;
  categoryParentName: string;
  co2: number;
  name: string;
  type: Array<number>;
  unit: string;
  created?: Timestamp;
  createdBy?: string;
}
const metadataCollection = collection(db, 'metadata');
const metadataDoc = doc(metadataCollection, 'metadata');

export const createCategory = async (userId: string, item: Category) => {
  const metadata = await getDoc(metadataDoc);
  const metadataData = metadata.exists() ? (metadata.data() as MetadataData) : { nextCategoryId: 0 };
  ConsoleHelper.log('metadataData:', metadataData);
  const { nextCategoryId } = metadataData;
  const categoryData = {
    ...item,
    ID: nextCategoryId,
    created: serverTimestamp(),
    createdBy: userId,
  };
  await setDoc(
    metadataDoc,
    { nextCategoryId: nextCategoryId + 1 }, // För att lägga till ett ID: number och inte bara ha firebase id-strängar
    { merge: true },
  );
  const newCategoryRef = await addDoc(categoryCollection, categoryData);
  // ConsoleHelper.log('newCategoryRef:', newCategoryRef.id);
  return newCategoryRef;
};

export const getCategory = (id: string) => {
  const documentRef = doc(categoryCollection, id);
  return getDoc(documentRef);
};

export const updateCategory = async (id: string, data: Category) => {
  const categoryRef = doc(categoryCollection, id);
  return updateDoc(categoryRef, data);
};

export const deleteCategory = (id: string) => {
  const documentRef = doc(categoryCollection, id);
  return deleteDoc(documentRef);
};

export const getCategories = async (categoryParentID?: number, type?: number[]): Promise<Category[]> => {
  const categoryRef = collection(db, 'category');

  let q: Query = query(categoryRef);

  if (categoryParentID) {
    q = query(q, where('categoryParentID', '==', categoryParentID));
  }
  if (type && type.length > 0) {
    q = query(q, where('type', 'array-contains-any', type));
  }

  q = query(q, where('companyId', '==', appConfig.companyId));

  const querySnapshot = await getDocs(q);
  const categories: Category[] = querySnapshot.docs.map((d) => {
    const data = d.data() as any; // Omit<Category, 'id'> & { id: string };
    const category: Category = { ...data, id: d.id };
    return category;
  });

  return categories;
};

export const setCompanyIDOnAllCategories = () => {
  getDocs(categoryCollection).then((snapshot) => {
    snapshot.forEach((x) => {
      const docRef = doc(categoryCollection, x.id);

      updateDoc(docRef, { companyId: appConfig.companyId })
        .then(() => {
          ConsoleHelper.log(`Document ${x.id} updated successfully.`);
        })
        .catch((error) => {
          ConsoleHelper.error(`Error updating document ${x.id}: ${error}`);
        });
    });
  });
};

export const streamCategories = (
  snapshot: (snapshot: QuerySnapshot<Category>) => void,
  error: (error: FirestoreError) => void,
) => {
  let q = query(categoryCollection, orderBy('categoryParentID', 'desc'));
  q = query(q, where('companyId', '==', appConfig.companyId));

  return onSnapshot(q, { includeMetadataChanges: true }, snapshot, error);
};
