import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledTextInput } from 'src/shared/components/controlledTextInput';
import { Colors } from 'src/shared/styles/colors';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { Category, createCategory } from 'src/services/category.service';
import ControlledSelect from 'src/shared/components/controlledSelect';
import ModalTemplate from 'src/shared/templates/modalTemplate';
import { CategoryGroups, CategoryTypes, Units } from 'src/shared/helpers/categoryData';
import { CategorySchema } from 'src/shared/models/categorySchema';
import ControlledSelectObject from 'src/shared/components/controlledSelectObject';
import { useState } from 'react';
import { appConfig } from 'src/firebaseConf';

type CreateCategoryModalProps = {
  onClose: () => void;
  modalOpen: boolean;
};

const CreateCategoryModal = (props: CreateCategoryModalProps) => {
  const { onClose, modalOpen } = props;
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  const formMethods = useForm({
    resolver: yupResolver(CategorySchema()),
    defaultValues: {
      category: '',
      co2: 0,
      name: '',
      type: [],
      unit: 'st',
    },
  });

  const { handleSubmit, reset } = formMethods;

  // TODO: Fix any
  const onPressSubmit = async (x: any) => {
    ConsoleHelper.log('x', x);
    const types = x.type.map((type: any) => JSON.parse(type).ID);
    const data: Category = {
      companyId: appConfig.companyId,
      name: x.name,
      categoryParentID: x.category.ID,
      categoryParentName: x.category.value,
      co2: x.co2,
      unit: x.unit,
      type: types,
    };
    ConsoleHelper.log('create category', data);
    try {
      const res = await createCategory(appConfig.companyId, data);
      ConsoleHelper.log('RES createCategory::', res);
      setErrorMessage('');
      reset();
      onClose();
    } catch (error) {
      ConsoleHelper.log('ERR createCategory::', error);
      setErrorMessage('Kunde inte spara kategori');
    }
  };

  return (
    <ModalTemplate
      isOpen={modalOpen}
      onClose={() => onClose()}
      isLoading={false}
      title={<Typography sx={{ fontWeight: 700, fontSize: 20 }}>{t('createCategory')}</Typography>}
      content={
        <Box
          id='form-container'
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <FormProvider {...formMethods}>
            <form
              id='form'
              onSubmit={handleSubmit(onPressSubmit)}
              style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
            >
              <ControlledTextInput name='name' label={t('name')} />
              <ControlledTextInput name='co2' label={t('CO2')} />
              <ControlledSelect name='unit' label={t('unit')} options={Units} />
              <ControlledSelectObject name='category' label={t('group')} options={CategoryGroups} multiple={false} />
              <ControlledSelectObject name='type' label={t('usedFor')} options={CategoryTypes} multiple />
              {errorMessage?.length > 0 && (
                <Box display='flex' my={2}>
                  <Typography flex={1}>{errorMessage}</Typography>
                </Box>
              )}
              <Box
                id='submit-container'
                sx={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'flex-end',
                  width: '100%',
                }}
              >
                <Button
                  type='submit'
                  variant='contained'
                  fullWidth
                  sx={{
                    height: '50px',
                    '&.Mui-disabled': {
                      background: Colors.GREEN_OPACITY,
                    },
                  }}
                >
                  {t('save')}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </Box>
      }
    />
  );
};

export default CreateCategoryModal;
