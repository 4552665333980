import { ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import { RoutesEnum } from 'src/shared/routes/routesEnum';
import { PageRoutes } from 'src/shared/routes/pageRoutes';
import { theme } from 'src/shared/styles/theme';
import HomePage from 'src/scenes/home/homePage';
import PageNotFound from 'src/scenes/notFound/notFoundPage';
import RegisterPurchase from './scenes/purchase/registerPurchase';
import RegisterPage from './scenes/register/registerPage';
import RegisterSelling from './scenes/selling/registerSelling';
import RegisterRepair from './scenes/repair/registerRepair';
import RegisterDisposed from './scenes/disposed/registerDisposed';
import ResultReport from './scenes/reports/pages/resultReport';
import AdminPage from './scenes/admin/pages/adminPage';
import AdminCategoriesPage from './scenes/admin/pages/adminCategoriesPage';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route element={<PageRoutes />}>
          <Route path={RoutesEnum.Default} element={<HomePage />} />
          <Route path={RoutesEnum.Register} element={<RegisterPage />} />
          <Route path={RoutesEnum.RegisterPurchase} element={<RegisterPurchase />} />
          <Route path={RoutesEnum.RegisterSelling} element={<RegisterSelling />} />
          <Route path={RoutesEnum.RegisterRepair} element={<RegisterRepair />} />
          <Route path={RoutesEnum.RegisterDisposed} element={<RegisterDisposed />} />
          <Route path={RoutesEnum.ResultReport} element={<ResultReport />} />
          <Route path={RoutesEnum.Admin} element={<AdminPage />} />
          <Route path={RoutesEnum.AdminCategories} element={<AdminCategoriesPage />} />
        </Route>
        <Route path={RoutesEnum.NotFound} element={<PageNotFound />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
