import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, Switch, FormControlLabel } from '@mui/material';

type ControlledSwitchProps = {
  name: string;
  label: string;
};

const ControlledSwitch = (props: ControlledSwitchProps) => {
  const { name, label } = props;
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl fullWidth>
          <FormControlLabel
            control={<Switch checked={field.value} onChange={(e) => field.onChange(e)} color='primary' />}
            label={label}
            labelPlacement='start'
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              border: `1px solid lightgray`,
              borderRadius: 1,
              p: 1,
              mx: 0,
            }}
          />
        </FormControl>
      )}
    />
  );
};

export default ControlledSwitch;
