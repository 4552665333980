import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';

type GenericDialogProps = {
  open: boolean;
  dialogTitle?: JSX.Element;
  dialogContent: JSX.Element;
  abortLabel?: string;
  saveLabel?: string;
  onClose: <T>(data?: T) => void;
  onSave: () => void;
};

export const GenericDialog = (props: GenericDialogProps) => {
  const { open, dialogTitle, dialogContent, abortLabel, saveLabel, onClose, onSave } = props;

  const { t } = useTranslation(['common']);

  const componentId = `dialog-${Date.now().toString(36) + Math.random().toString(36).substring(2)}`;

  const handleClose = () => {
    onClose();
  };

  const onHandleSave = () => {
    onSave();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby={dialogTitle ? `${componentId}-titel` : undefined}
      open={open}
      maxWidth='sm'
      sx={{
        '& .MuiDialogContent-root': {
          p: 2,
        },
        '& .MuiDialogActions-root': {
          p: 1,
        },
      }}
    >
      {dialogTitle && (
        <DialogTitle sx={{ m: 0, p: 2 }} id={`${componentId}-titel`}>
          {dialogTitle}
        </DialogTitle>
      )}
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <>
          <Button variant='outlined' color='primary' onClick={handleClose}>
            {t(abortLabel ?? '')}
          </Button>
          <Button autoFocus variant='contained' color='primary' onClick={onHandleSave}>
            {t(saveLabel ?? '')}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

GenericDialog.defaultProps = {
  dialogTitle: null,
  abortLabel: 'no',
  saveLabel: 'yes',
};
