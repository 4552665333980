import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { useEffect, useState } from 'react';
import { Category, streamCategories } from 'src/services/category.service';
import CategoryTable from '../components/categoryTable';
import CreateCategoryModal from '../components/createCategoryModal';

const AdminCategoriesPage = () => {
  const { t } = useTranslation();

  const [categories, setCategories] = useState<Category[]>();
  const [createOpen, setCreateOpen] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = streamCategories(
      (querySnapshot) => {
        const newCategories = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return { id: doc.id, ...data };
        });
        setCategories(newCategories);
      },
      (error) => ConsoleHelper.log(error.message),
    );
    return unsubscribe;
  }, []);

  return (
    <PageTemplate
      showBackArrow
      fullWidth
      title={t('handleCategories')}
      subtitle=''
      content={
        <Box>
          <Button sx={{ mb: 2 }} variant='outlined' onClick={() => setCreateOpen(true)}>
            {t('Lägg till ny')}
          </Button>
          <Typography sx={{ fontWeight: 700 }}>{t('currentCategories')}</Typography>
          <CategoryTable categories={categories ?? []} />
          <CreateCategoryModal modalOpen={createOpen} onClose={() => setCreateOpen(false)} />
        </Box>
      }
      onClickButton={() => ConsoleHelper.log('Hidden button')}
      hideButton
      isButtonDisabled
    />
  );
};

export default AdminCategoriesPage;
