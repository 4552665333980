/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { Colors } from 'src/shared/styles/colors';
import { useTranslation } from 'react-i18next';
import { GenericDialog } from 'src/shared/components/genericDialog';
import { Entrance, deleteEntrance, streamEntrances } from 'src/services/entrance.service';
import { Dispose, streamDisposals } from 'src/services/dispose.service';
import { EntranceDataGrid } from './entranceDataGrid';

type DisposeReportProps = {
  startDate: Date;
  endDate: Date;
  showAllDates: boolean;
};
const EntranceReport = (props: DisposeReportProps) => {
  const { startDate, endDate, showAllDates } = props;
  const { t } = useTranslation();
  const [data, setData] = useState<QueryDocumentSnapshot<Entrance>[]>([]);
  const [disposalData, setDisposalData] = useState<QueryDocumentSnapshot<Dispose>[]>([]);
  const [averageDisposeRegsPerEntrances, setAverageDisposeRegsPerEntrances] = useState<number>(0);
  const [averageDisposesPerEntrances, setAverageDisposesPerEntrances] = useState<number>(0);
  const [totalDisposeQuantity, setTotalDisposeQuantity] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedID, setSelectedID] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = streamEntrances(
      (querySnapshot) => {
        setData(querySnapshot.docs);
      },
      (error) => ConsoleHelper.log(error.message),
      showAllDates ? undefined : startDate,
      showAllDates ? undefined : endDate,
    );
    return unsubscribe;
  }, [endDate, startDate, showAllDates]);

  useEffect(() => {
    const unsubscribe = streamDisposals(
      (querySnapshot) => {
        setDisposalData(querySnapshot.docs);
      },
      (error) => ConsoleHelper.log(error.message),
      showAllDates ? undefined : startDate,
      showAllDates ? undefined : endDate,
    );
    return unsubscribe;
  }, [endDate, startDate, showAllDates]);

  useEffect(() => {
    const entranceDataLength = data?.length ?? 0;
    const disposeDataLength = disposalData?.length ?? 0;
    if (entranceDataLength === 0) {
      setAverageDisposeRegsPerEntrances(0);
    } else {
      setAverageDisposeRegsPerEntrances(disposeDataLength / entranceDataLength);
    }
  }, [data, disposalData]);

  useEffect(() => {
    const disposals = disposalData.map((doc: QueryDocumentSnapshot<Dispose>) => doc.data());
    const totalQuantity = disposals.reduce((total, disposal) => total + disposal.quantity, 0);
    setTotalDisposeQuantity(totalQuantity);
    const entranceDataLength = data?.length ?? 0;
    if (entranceDataLength === 0) {
      setAverageDisposesPerEntrances(0);
    } else {
      setAverageDisposesPerEntrances(totalQuantity / entranceDataLength);
    }
  }, [data, disposalData]);

  const onDeleteClicked = async (id: string) => {
    try {
      const res = await deleteEntrance(id);
      ConsoleHelper.log('res deleting', res);
      setSelectedID(null);
    } catch (error) {
      ConsoleHelper.log('error deleting', error);
      setSelectedID(null);
    }
  };

  const handleOnDelete = () => {
    if (selectedID) {
      onDeleteClicked(selectedID);
      setModalOpen(false);
    }
  };

  const formatNumber = (num: number) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(1);
  };

  return (
    <Box mb={3}>
      <Typography variant='h6' sx={{ color: Colors.STEEL, mb: 1 }}>
        {t('registeredEntries')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2} mb={2} display='flex' alignItems='stretch'>
        <Grid item xs={12} md={4}>
          <Tooltip
            title={
              <Typography fontSize={12}>
                Vid registrering av avfall har man möjligheten att även registrera en inpassering
              </Typography>
            }
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 1,
                border: '1px solid rgba(224, 224, 224, 1)',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Typography>{t('numberOfEntries')}</Typography>
              <Typography variant='h3'>{data?.length}</Typography>
            </Box>
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={4}>
          <Tooltip
            title={
              <Typography fontSize={12}>
                {`Snittvärde - antalet registeringar (rader på tabellen för avfall) är ${disposalData?.length ?? 0} st`}
              </Typography>
            }
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 1,
                border: `1px solid rgba(224, 224, 224, 1)`,
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Typography>{t('numberOfWasteRegsPerEntry')}</Typography>
              <Typography variant='h3'>{formatNumber(averageDisposeRegsPerEntrances)}</Typography>
            </Box>
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={4}>
          <Tooltip
            title={
              <Typography fontSize={12}>
                {`Snittvärde - antalet produkter är summan av antalet på alla avfallsregistreringar, ${totalDisposeQuantity} st`}
              </Typography>
            }
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 1,
                border: `1px solid rgba(224, 224, 224, 1)`,
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Typography>{t('numberOfWastePerEntry')}</Typography>
              <Typography variant='h3'>{formatNumber(averageDisposesPerEntrances)}</Typography>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
      <EntranceDataGrid
        data={data}
        onClickDelete={(id: string) => {
          setSelectedID(id);
          setModalOpen(true);
        }}
      />
      <GenericDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleOnDelete}
        dialogContent={
          <Box>
            <Typography>{t('confirmDeleteRegistration')}</Typography>
          </Box>
        }
      />
    </Box>
  );
};

export default EntranceReport;
