import { TextField } from '@mui/material';
import { Controller, useFormContext, useFormState } from 'react-hook-form';

type ControlledTextInputProps = {
  name: string;
  label: string;
  multiline?: boolean;
  type?: string;
};

export const ControlledTextInput = (props: ControlledTextInputProps) => {
  const { name, label, multiline, type } = props;
  const { control } = useFormContext();
  const { errors } = useFormState();

  let isError = false;
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <TextField
            {...field}
            {...props}
            error={isError}
            label={label}
            id={name}
            multiline={multiline}
            type={type}
            fullWidth
            margin='dense'
          />
        );
      }}
    />
  );
};

ControlledTextInput.defaultProps = {
  multiline: false,
  type: 'text',
};
