import * as React from 'react';
import { Box, Popover } from '@mui/material';

type ImagePopoverProps = {
  image: string;
  title: string;
  children: JSX.Element;
};

export const ImagePopover = (props: ImagePopoverProps) => {
  const { image, title, children } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const componentId = `popover-${Date.now().toString(36) + Math.random().toString(36).substring(2)}`;

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Box aria-haspopup='true' onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        {children}
      </Box>
      <Popover
        id={componentId}
        sx={{
          pointerEvents: 'none',
          borderRadius: 0,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        hideBackdrop
        disableRestoreFocus
      >
        <img src={image} alt={title} style={{ maxWidth: '100%', maxHeight: '500px' }} />
      </Popover>
    </Box>
  );
};
