// Översättningspaket i18n
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationSE from 'src/shared/locales/se.json';
import translationEN from 'src/shared/locales/en.json';

const resources = {
  en: { common: translationEN },
  se: { common: translationSE },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'se',
  lng: 'se',
  supportedLngs: ['se', 'en'],
  ns: ['common'],
  defaultNS: 'common',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
