import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField, FormControlLabel, InputAdornment, IconButton, Box } from '@mui/material';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';
import IconCalendarPNG from 'src/assets/calendar@2x.png';
import { useState } from 'react';

type ControlledDatePickerProps = {
  name: string;
  label: string;
};

const ControlledDatePicker = (props: ControlledDatePickerProps) => {
  const { name, label } = props;
  const { control } = useFormContext();
  const { errors } = useFormState();

  // Eget state för att kunna öppna via custom end icon
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let isError = false;
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box ml='10px' mr='-10px'>
          <FormControlLabel
            sx={{ width: '100%' }}
            label=''
            control={
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={sv}>
                <MobileDatePicker
                  inputFormat='YYYY-MM-DD'
                  mask='____-__-__'
                  minDate={dayjs('2020-01-01')}
                  value={field.value}
                  open={open}
                  onOpen={handleOpen}
                  onClose={handleClose}
                  onChange={(e) => field.onChange(e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={isError}
                      label={label}
                      margin='dense'
                      sx={{ mb: 1 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton edge='end' onClick={handleOpen}>
                              <img src={IconCalendarPNG} height='30px' width='30px' alt='calendar' />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            }
          />
        </Box>
      )}
    />
  );
};

export default ControlledDatePicker;
