export const Colors = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  STEEL: '#8D93A5',
  GRAY: '#0000002B',
  GRAY_LIGHT: '#F1F1F1',
  GREEN: '#A0CF96',
  GREEN_OPACITY: '#A0CF967F',
  RED: '#823038',
  ORANGE: '#E57A44',
};
