import * as yup from 'yup';

export const DisposeSchema = () =>
  yup.object().shape({
    date: yup.date().required(),
    category: yup.object().required().default(undefined),
    title: yup.string(),
    description: yup.string().optional(),
    quantity: yup.number().required(),
    createdBy: yup.string().required(),
    // created: yup.string().optional(),
  });
