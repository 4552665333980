/* eslint-disable class-methods-use-this */
import dayjs from 'dayjs';
import {
  deleteDoc,
  doc,
  addDoc,
  serverTimestamp,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
  orderBy,
  FirestoreError,
  QuerySnapshot,
  Timestamp,
} from 'firebase/firestore';
import { appConfig, entranceCollection } from '../firebaseConf';

export interface Entrance {
  companyId: string;
  created: Timestamp;
  createdBy: string;
}

export const createEntrance = async (userId: string) => {
  return addDoc(entranceCollection, {
    companyId: appConfig.companyId,
    created: serverTimestamp(),
    createdBy: userId,
  });
};

export const getEntrance = (id: string) => {
  const documentRef = doc(entranceCollection, id);
  return getDoc(documentRef);
};

export const deleteEntrance = (id: string) => {
  const documentRef = doc(entranceCollection, id);
  return deleteDoc(documentRef);
};

export const getEntrances = () => {
  return getDocs(entranceCollection);
};

export const streamEntrances = (
  snapshot: (snapshot: QuerySnapshot<Entrance>) => void,
  error: (error: FirestoreError) => void,
  start?: Date,
  end?: Date,
) => {
  let q = query(entranceCollection, orderBy('created', 'desc'));
  q = query(q, where('companyId', '==', appConfig.companyId));
  if (start && end) {
    const startTimestamp = Timestamp.fromDate(dayjs(start).toDate());
    const endTimestamp = Timestamp.fromDate(dayjs(end).toDate());
    q = query(
      entranceCollection,
      where('companyId', '==', appConfig.companyId),
      where('created', '>=', startTimestamp),
      where('created', '<=', endTimestamp),
      orderBy('created', 'desc'),
    );
  }

  return onSnapshot(q, { includeMetadataChanges: true }, snapshot, error);
};
