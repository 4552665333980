import { InfoOutlined } from '@mui/icons-material';
import { Box, Icon, Tooltip, Typography } from '@mui/material';

export const calculateCO2Comparison = (co2Saved: number) => {
  const CAR_EMISSIONS_PER_KM = 0.12; // kg CO2 per km
  const CAR_MODEL_AVG_KM_PER_KG = 20; // km per kg CO2 (avg for all car models)
  const LAPTOP_EMISSIONS_PER_HOUR = 0.01; // kg CO2 per hour
  const LAPTOP_USAGE_HOURS_PER_KG = 100; // hours per kg CO2
  const SMARTPHONE_EMISSIONS_PER_CHARGE = 0.0005; // kg CO2 per charge
  const SMARTPHONE_CHARGES_PER_KG = 2000; // charges per kg CO2
  const SHOWER_EMISSIONS_PER_MINUTE = 0.005; // kg CO2 per minute

  const carDistance = (co2Saved / CAR_EMISSIONS_PER_KM) * CAR_MODEL_AVG_KM_PER_KG;
  const laptopUsage = (co2Saved / LAPTOP_EMISSIONS_PER_HOUR) * LAPTOP_USAGE_HOURS_PER_KG;
  const smartphoneCharges = (co2Saved / SMARTPHONE_EMISSIONS_PER_CHARGE) * SMARTPHONE_CHARGES_PER_KG;
  const showerMinutes = co2Saved / SHOWER_EMISSIONS_PER_MINUTE;

  const comparisons = [
    {
      key: 'carDistance',
      text: `Detta kan jämföras med att köra en genomsnittlig fossilbil i ${
        carDistance > 0 ? carDistance.toFixed(1) : carDistance.toFixed(1)
      } km.`,
    },
    {
      key: 'laptopUsage',
      text: `Detta kan jämföras med att använda en laptop i ${
        laptopUsage > 0 ? laptopUsage.toFixed(0) : -laptopUsage.toFixed(0)
      } timmar.`,
    },
    {
      key: 'smartphoneCharges',
      text: `Detta kan jämföras med att ladda din smartphone ${
        smartphoneCharges > 0 ? smartphoneCharges.toFixed(0) : -smartphoneCharges.toFixed(0)
      } gånger.`,
    },
    {
      key: 'showerMinutes',
      text: `Detta kan jämföras med att duscha i ${
        showerMinutes > 0 ? showerMinutes.toFixed(0) : -showerMinutes.toFixed(0)
      } minuter.`,
    },
  ];

  const comparison = comparisons[Math.floor(Math.random() * comparisons.length)];

  return comparison.text;
};

export const EmissionTooltip = () => {
  // const tooltipText =
  // 'CO2-jämförelserna är räknade utifrån nedan generella värden:\nFossilbilar
  // 0.12 kg CO2/km & 20km/kg CO2. \nLaptopanvändning 0.01 kg CO2/timme & 100 timme/kg CO2.
  // \nSmartphoneladdning 0.0005 kg CO2/laddning & 2000 laddningar/kg CO2. \nDusch 0.005 kg CO2/minut';

  return (
    <Box sx={{ ml: 4 }}>
      <Tooltip
        // title={tooltipText}
        title={
          <Box>
            <Typography fontSize={12} fontWeight='bold'>
              CO2-jämförelserna är beräknade utifrån generella värden:
            </Typography>
            <Typography fontSize={12}>Fossilbilar 0.12 kg CO2/km & 20km/kg CO2</Typography>
            <Typography fontSize={12}>Laptopanvändning 0.01 kg CO2/timme & 100 timme/kg CO2</Typography>
            <Typography fontSize={12}>Smartphoneladdning 0.0005 kg CO2/laddning & 2000 laddningar/kg CO2</Typography>
            <Typography fontSize={12}>Dusch 0.005 kg CO2/minut</Typography>
          </Box>
        }
      >
        <Icon>
          <InfoOutlined color='disabled' fontSize='medium' />
        </Icon>
      </Tooltip>
    </Box>
  );
};
