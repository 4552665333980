/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Purchase } from 'src/services/purchase.service';

const COLORS = ['#8D93A5', '#A0CF96', '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
      style={{ fontSize: 12 }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const renderCustomizedLabel2 = ({ cx, cy, midAngle, innerRadius, outerRadius, value }: any) => {
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
      {`${value} kr`}
    </text>
  );
};

export const RenderPieChart = ({ data }: any) => {
  const purchaseData = data.map((doc: QueryDocumentSnapshot<Purchase>) => doc.data());
  // const purchaseData: any = [];
  const totalPriceNew = purchaseData
    .filter((purchase: Purchase) => !purchase.used)
    .reduce((sum: number, purchase: Purchase) => sum + purchase.price * purchase.quantity, 0);
  const totalPriceUsed = purchaseData
    .filter((purchase: Purchase) => purchase.used)
    .reduce((sum: number, purchase: Purchase) => sum + purchase.price * purchase.quantity, 0);

  const purchaseChartData = [
    {
      name: 'Nytt',
      value: purchaseData.filter((x: Purchase) => !x.used).length,
      totalPrice: totalPriceNew,
      color: '#0088FE',
    },
    {
      name: 'Begagnat',
      value: purchaseData.filter((x: Purchase) => x.used).length,
      totalPrice: totalPriceUsed,
      color: '#00C49F',
    },
  ];
  return (
    <ResponsiveContainer height={300} width='100%'>
      <PieChart height={250} data={purchaseChartData}>
        <Tooltip />
        <Legend verticalAlign='top' height={30} />
        <Pie
          data={purchaseChartData}
          cx='50%'
          cy='50%'
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={70}
          dataKey='value'
          legendType='none'
        >
          {purchaseChartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Pie
          data={purchaseChartData}
          dataKey='totalPrice'
          nameKey='name'
          cx='50%'
          cy='50%'
          innerRadius={75}
          outerRadius={90}
          label={renderCustomizedLabel2}
        >
          {purchaseChartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
