import {
  // FieldValue,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RepairSchema } from 'src/shared/models/repairSchema';
import dayjs from 'dayjs';
import { ControlledTextInput } from 'src/shared/components/controlledTextInput';
import ControlledDatePicker from 'src/shared/components/controlledDatePicker';
import { Colors } from 'src/shared/styles/colors';
import ControlledSelectGrouped from 'src/shared/components/controlledSelectGrouped';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { Repair } from 'src/services/repair.service';
import { useEffect, useState } from 'react';
import { Category, getCategories } from 'src/services/category.service';
import { appConfig } from 'src/firebaseConf';

type RepairFormProps = {
  setRepairData: (arg: Repair) => void;
  showForm: boolean;
};

const RepairForm = (props: RepairFormProps) => {
  const { setRepairData, showForm } = props;
  const { t } = useTranslation();
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await getCategories(undefined, [3]);
      setCategories(res);
    };
    fetchCategories();
  }, []);

  const formMethods = useForm({
    resolver: yupResolver(RepairSchema()),
    defaultValues: {
      date: dayjs().toDate(),
      category: '',
      title: '',
      description: '',
      price: '',
      time: '',
      quantity: 1,
      createdBy: appConfig.companyId,
      //   created: serverTimestamp() as Timestamp,
    },
  });

  const { handleSubmit, watch } = formMethods;

  // TODO: Fix any
  const onPressSubmit = async (x: any) => {
    ConsoleHelper.log('x', x);
    const submitdata: Repair = {
      companyId: appConfig.companyId,
      date: new Date(x.date),
      category: x.category,
      categoryParent: x.category.categoryParentName,
      title: x.title,
      description: x.description,
      price: x.price ?? 0,
      time: x.time ?? 0,
      quantity: x.quantity,
      createdBy: appConfig.companyId,
      created: serverTimestamp() as Timestamp,
    };
    ConsoleHelper.log('submitdata', submitdata);
    setRepairData(submitdata); // Sätter datat i state på mammasidan
  };

  const categoryValue = watch('category');
  const parsedCategory = categoryValue ? JSON.parse(categoryValue) : undefined;

  return (
    <Box
      id='form-container'
      mt={2}
      sx={{
        display: showForm ? 'flex' : 'none',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <FormProvider {...formMethods}>
        <form
          id='form'
          onSubmit={handleSubmit(onPressSubmit)}
          style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
        >
          <ControlledDatePicker name='date' label={t('date')} />
          <ControlledSelectGrouped name='category' label={t('category')} options={categories} />
          <ControlledTextInput name='title' label={t('productTitle')} />
          <ControlledTextInput name='description' label={t('description')} />
          <ControlledTextInput
            name='quantity'
            label={`${t('quantity')} (${parsedCategory ? parsedCategory.unit : 'st'})`}
            type='number'
          />
          <ControlledTextInput
            name='price'
            label={`${t('cost')}/${parsedCategory ? parsedCategory.unit : 'st'} (ange 0 om ingen kostnad)`}
            type='number'
          />
          <ControlledTextInput
            name='time'
            label={`${t('time')} i ${t('min')} (ange 0 om ingen egen tid lagd)`}
            type='number'
          />
          <Box
            id='submit-container'
            sx={{
              display: 'flex',
              flex: 1,
              alignItems: 'flex-end',
              width: '100%',
            }}
          >
            <Button
              type='submit'
              variant='contained'
              fullWidth
              sx={{
                height: '50px',
                '&.Mui-disabled': {
                  background: Colors.GREEN_OPACITY,
                },
              }}
            >
              {t('next')}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default RepairForm;
