/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import { Box, Typography } from '@mui/material';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Dispose } from 'src/services/dispose.service';
// import ConsoleHelper from 'src/shared/helpers/consoleHelper';

const COLORS = ['#8D93A5', '#A0CF96', '#FFBB28', '#FF8042'];
const COLORS2 = ['#8D93A5', '#E57A44', '#823038', '#A0CF96'];
const RADIAN = Math.PI / 180;

export const CustomTooltip = ({ active, payload, total }: any) => {
  if (active && payload && payload.length) {
    const { unit, category, sum } = payload[0].payload;
    const percent = sum / total;
    let tooltipText = '';
    if (process.env.REACT_APP_COMPANY_ID === 'Milav') {
      tooltipText = `Det har slängts ${sum} ${unit} ${category} vilket avser ${(percent * 100).toFixed(
        0,
      )}% av totalt ${total} ${unit} inom kategorierna övrigt avfall och förpackningar`;
    } else {
      tooltipText = `Det har slängts ${sum} ${unit} ${category} vilket avser ${(percent * 100).toFixed(
        0,
      )}% av totalt ${total} ${unit}`;
    }
    return (
      <Box sx={{ bgcolor: 'white', border: '1px solid #dfdfdf', p: 1 }}>
        <Typography>{tooltipText}</Typography>
      </Box>
    );
  }
  return null;
};

const RenderInnerCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.45;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
      style={{ fontSize: 12 }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const RenderOuterCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, payload }: any) => {
  const { unit } = payload.payload;
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
      {`${value} ${unit}`}
    </text>
  );
};

// MILAV: Diagrammet bör (i nämnd ordning) visa grupperna:
// Restavfall, Matavfall, Returpapper, Metall, Plast, Kartong, Glas
export const RenderPieChartSubCategory = ({ data }: any) => {
  let disposals = [];
  if (process.env.REACT_APP_COMPANY_ID === 'Milav') {
    disposals = data
      .filter((doc: QueryDocumentSnapshot<Dispose>) => doc.data().category.categoryParentID !== (1 || 2))
      .map((doc: QueryDocumentSnapshot<Dispose>) => doc.data());
  } else {
    disposals = data.map((doc: QueryDocumentSnapshot<Dispose>) => doc.data());
  }

  const categoryOrder = ['Restavfall', 'Matavfall', 'Returpapper', 'Metall', 'Plast', 'Kartong', 'Glas'];

  const disposeData = disposals
    .reduce((accumulator: any, dispose: Dispose) => {
      const { category } = dispose;
      const existingGroup = accumulator.find((group: any) => group.category === category.name);
      const disposeQuantity = dispose.quantity;

      if (existingGroup) {
        existingGroup.sum += disposeQuantity;
      } else {
        accumulator.push({
          category: category.name,
          sum: disposeQuantity,
          unit: category.unit,
        });
      }
      return accumulator;
    }, [])
    .sort((a: any, b: any) => categoryOrder.indexOf(a.category) - categoryOrder.indexOf(b.category));

  const totalQuantity = disposals.reduce((sum: number, disposal: Dispose) => sum + disposal.quantity, 0);

  return (
    <ResponsiveContainer height={300} width='100%'>
      <PieChart data={disposeData}>
        <Tooltip content={<CustomTooltip total={totalQuantity} />} />
        <Legend verticalAlign='top' height={30} />
        <Pie
          data={disposeData}
          dataKey='sum'
          nameKey='category'
          cx='50%'
          cy='50%'
          outerRadius={70}
          labelLine={false}
          label={<RenderInnerCustomizedLabel />}
          legendType='none'
        >
          {disposeData.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS.length]} />
          ))}
        </Pie>
        <Pie
          data={disposeData}
          dataKey='sum'
          nameKey='category'
          cx='50%'
          cy='50%'
          innerRadius={75}
          outerRadius={90}
          label={<RenderOuterCustomizedLabel />}
        >
          {disposeData.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
