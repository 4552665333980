import { useLocation, useNavigate } from 'react-router-dom';
import { WarningRounded } from '@mui/icons-material';
import { Box, Typography, Button, Container } from '@mui/material';
import { Colors } from 'src/shared/styles/colors';
import { RoutesEnum } from 'src/shared/routes/routesEnum';

const PageNotFound = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Container maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography align='center'>
          <WarningRounded sx={{ fontSize: '5rem', color: Colors.STEEL }} />
        </Typography>
        <Typography variant='h6' align='center' gutterBottom>
          Sökvägen {pathname} hittades inte
        </Typography>
        <Button
          variant='contained'
          sx={{
            mt: 2,
            mb: 2,
          }}
          onClick={() => navigate(RoutesEnum.Default)}
        >
          Gå till start
        </Button>
      </Box>
    </Container>
  );
};

export default PageNotFound;
