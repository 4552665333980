import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import { Colors } from 'src/shared/styles/colors';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type PageTemplateProps = {
  showBackArrow?: boolean;
  title: string;
  subtitle: string;
  content: JSX.Element;
  buttonTitle?: string;
  onClickButton: () => void;
  onClickBack?: () => void;
  isButtonDisabled?: boolean;
  hideButton?: boolean;
  fullWidth?: boolean;
};

export const PageTemplate = (props: PageTemplateProps) => {
  const {
    showBackArrow,
    onClickBack,
    title,
    subtitle,
    content,
    buttonTitle,
    onClickButton,
    isButtonDisabled,
    hideButton,
    fullWidth,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goBack = () => {
    if (onClickBack) onClickBack();
    else navigate(-1);
  };
  return (
    <Container
      id='main-wrapper'
      maxWidth={fullWidth ? 'lg' : 'sm'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: Colors.WHITE,
        p: 3,
      }}
    >
      <Box sx={{ height: '50px' }}>
        {showBackArrow && (
          <IconButton color='primary' onClick={() => goBack()}>
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
        )}
      </Box>
      <Box id='main-content' p={1} display='flex' flex={1} flexDirection='column' minHeight='70vh'>
        <Box
          sx={{
            p: 1,
          }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: 20 }}>{title}</Typography>
        </Box>
        {subtitle !== '' && (
          <Box
            sx={{
              p: 1,
            }}
          >
            <Typography sx={{ fontSize: 14, color: Colors.STEEL }}>{subtitle}</Typography>
          </Box>
        )}
        <Box id='page-content' display='flex' flex={1} flexDirection='column' p={1}>
          {content}
          {!hideButton && (
            <Box
              id='page-button-container'
              sx={{
                display: 'flex',
                flex: 1,
                alignItems: 'flex-end',
                width: '100%',
              }}
            >
              <Button
                variant='contained'
                onClick={onClickButton}
                fullWidth
                disabled={isButtonDisabled}
                sx={{
                  height: '50px',
                  '&.Mui-disabled': {
                    background: Colors.GREEN_OPACITY,
                  },
                }}
              >
                {buttonTitle ?? t('continue')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

PageTemplate.defaultProps = {
  showBackArrow: true,
  onClickBack: undefined,
  buttonTitle: undefined,
  isButtonDisabled: false,
  hideButton: false,
  fullWidth: false,
};
