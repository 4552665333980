import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createPurchase, Purchase } from 'src/services/purchase.service';
import dayjs from 'dayjs';
import { Colors } from 'src/shared/styles/colors';
import { useState } from 'react';

type PurchaseSummaryProps = {
  data: Purchase;
  imageData: string;
  showSummary: boolean;
  onSubmitSuccess: () => void;
};
// TODO: Posta, resetta form & visa bekräftelsevy
const PurchaseSummary = (props: PurchaseSummaryProps) => {
  const { data, imageData, showSummary, onSubmitSuccess } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const onPressConfirm = async () => {
    // ConsoleHelper.log('submitdata', submitdata);
    try {
      const res = await createPurchase(process.env.REACT_APP_COMPANY_ID ?? '1234', data, imageData);
      ConsoleHelper.log('RES createPurchase::', res);
      setErrorMessage('');
      onSubmitSuccess();
    } catch (error) {
      ConsoleHelper.log('ERR createPurchase::', error);
      setErrorMessage('Kunde inte spara registrering');
    }
  };

  return (
    <Box
      mt={2}
      sx={{
        display: showSummary ? 'flex' : 'none',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <Typography variant='h4'>{data.title}</Typography>
      {data.description?.length > 0 && <Typography>{data.description}</Typography>}
      <Box display='flex' mt={2} mb={1}>
        <Typography fontWeight={700} flex={4}>
          {t('category')}
        </Typography>
        <Typography textAlign='right' flex={6}>
          {data.category.name}
        </Typography>
      </Box>
      <Box display='flex' my={1}>
        <Typography fontWeight={700} flex={4}>
          {t('date')}
        </Typography>
        <Typography textAlign='right' flex={6}>
          {dayjs(data.date).format('YYYY-MM-DD')}
        </Typography>
      </Box>
      <Box display='flex' my={1}>
        <Typography fontWeight={700} flex={4}>
          {t('price')}
        </Typography>
        <Typography textAlign='right' flex={6}>
          {data.price} kr/{data.category.unit}
        </Typography>
      </Box>
      <Box display='flex' my={1}>
        <Typography fontWeight={700} flex={4}>
          {t('quantity')}
        </Typography>
        <Typography textAlign='right' flex={6}>
          {data.quantity} {data.category.unit}
        </Typography>
      </Box>
      <Box display='flex' mt={1} mb={2}>
        <Typography fontWeight={700} flex={4}>
          {t('used')}
        </Typography>
        <Typography textAlign='right' flex={6}>
          {data.used ? t('yes') : t('no')}
        </Typography>
      </Box>
      {imageData && (
        <div>
          <img src={imageData} alt={data.title} style={{ maxWidth: '100%', maxHeight: '200px' }} />
        </div>
      )}

      <Divider />

      <Box display='flex' my={2}>
        <Typography fontWeight={700} flex={4}>
          {t('sum')}
        </Typography>
        <Typography textAlign='right' flex={6}>
          {data.price * data.quantity} kr
        </Typography>
      </Box>
      {errorMessage?.length > 0 && (
        <Box display='flex' my={2}>
          <Typography flex={1}>{errorMessage}</Typography>
        </Box>
      )}
      <Box
        id='submit-container'
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'flex-end',
          width: '100%',
        }}
      >
        <Button
          variant='contained'
          onClick={onPressConfirm}
          fullWidth
          sx={{
            height: '50px',
            '&.Mui-disabled': {
              background: Colors.GREEN_OPACITY,
            },
          }}
        >
          {t('confirmRegister')}
        </Button>
      </Box>
    </Box>
  );
};

export default PurchaseSummary;
