import { Box, Tooltip } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { QueryDocumentSnapshot, Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';
import { Entrance } from 'src/services/entrance.service';
import { DeleteButton } from '../../../shared/components/reportItems';

export const EntranceDataGrid = ({ data, onClickDelete }: any) => {
  const items = data.map((doc: QueryDocumentSnapshot<Entrance>) => doc);
  const columns = [
    {
      field: 'createdBy',
      headerName: 'Skapad av',
      flex: 0.25,
      renderCell: (params: any) => (
        <Tooltip title={params.row.createdBy}>
          <span>{params.row.createdBy}</span>
        </Tooltip>
      ),
    },
    {
      field: 'created',
      headerName: 'Datum',
      flex: 0.15,
      renderCell: (params: any) => (
        <Tooltip title={params.row.created}>
          <span>{params.row.created}</span>
        </Tooltip>
      ),
    },
    {
      field: 'id',
      headerName: '',
      sortable: false,
      width: 30,
      renderCell: (params: any) => {
        const { id } = params.row;
        return <DeleteButton onClick={() => onClickDelete(id)} />;
      },
    },
  ];

  const rows = items.map((doc: any) => {
    const convertTimestampToDate = (timestamp: Timestamp | any): Date | any => {
      return timestamp instanceof Timestamp
        ? new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate()
        : timestamp;
    };
    const dateCreated = convertTimestampToDate(doc.data().created);

    return {
      id: doc.id,
      created: dayjs(dateCreated).format('YYYY-MM-DD'),
      createdBy: doc.data().createdBy,
    };
  });

  return (
    <Box sx={{ maxHeight: 400 }}>
      <DataGrid autoHeight rows={rows} columns={columns} pageSize={100} components={{ Toolbar: GridToolbar }} />
    </Box>
  );
};
