import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { Select, MenuItem, InputLabel, FormControl, ListSubheader } from '@mui/material';
import { Category } from 'src/services/category.service';

type ControlledSelectGroupedProps = {
  name: string;
  label: string;
  options: Category[];
};

const ControlledSelectGrouped = (props: ControlledSelectGroupedProps) => {
  const { name, label, options } = props;
  const { control } = useFormContext();
  const { errors } = useFormState();

  let isError = false;
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
  }

  const labelId = `${name}-label`;
  const selectId = `${name}-select`;

  const renderSelectGroup = (categoryOptions: Category[]) => {
    const groupedCategories = categoryOptions.reduce((acc: Record<number, any>, category) => {
      if (!acc[category.categoryParentID]) {
        acc[category.categoryParentID] = {
          parentCategoryName: category.categoryParentName,
          categories: [category],
        };
      } else {
        acc[category.categoryParentID].categories.push(category);
      }
      return acc;
    }, {});

    return Object.values(groupedCategories).map((group) => {
      const { parentCategoryName, categories } = group;
      const items = categories.map((category: Category) => (
        <MenuItem key={category.ID} value={JSON.stringify(category)}>
          {category.name}
        </MenuItem>
      ));
      return [<ListSubheader key={parentCategoryName}>{parentCategoryName}</ListSubheader>, ...items];
    });
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl error={isError} fullWidth>
          <InputLabel id={labelId}>{label}</InputLabel>
          <Select
            {...field}
            label={label}
            labelId={labelId}
            id={selectId}
            onChange={field.onChange}
            value={field.value}
          >
            {renderSelectGroup(options)}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default ControlledSelectGrouped;
