import { useEffect, useState } from 'react';
import { Category, getCategories } from 'src/services/category.service';
import ConsoleHelper from './consoleHelper';

export const useFetchCategories = (parentCategoryID?: number, types?: number[]) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const res = await getCategories(parentCategoryID, types);
        setCategories(res);
      } catch (err: any) {
        ConsoleHelper.log('ERROR CATEGORIES', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    return () => {
      fetchCategories();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { categories, error, loading };
};
