import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from '@mui/material';
import { Category, deleteCategory } from 'src/services/category.service';
import { TypeEnum } from 'src/shared/helpers/typeEnum';
import { useTranslation } from 'react-i18next';
import { DeleteButton, EditButton } from 'src/shared/components/reportItems';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { GenericDialog } from 'src/shared/components/genericDialog';
import EditCategoryModal from './editCategoryModal';

interface CategoryTableProps {
  categories: Category[];
}
const CategoryTable = ({ categories }: CategoryTableProps) => {
  const { t } = useTranslation();
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);

  // Hämta översättningsnyckeln från process.env
  const translationKeyDispose = process.env.REACT_APP_DISPOSE_KEY;

  const handleEdit = (category: Category) => {
    setSelectedCategory(category);
    setEditOpen(true);
  };

  const onDeleteClicked = async (id: string) => {
    try {
      await deleteCategory(id);
      setSelectedCategory(null);
    } catch (error) {
      ConsoleHelper.log('error deleting', error);
      setSelectedCategory(null);
    }
  };

  const handleOnDelete = () => {
    if (selectedCategory) {
      onDeleteClicked(selectedCategory?.id ?? '');
      setModalDeleteOpen(false);
    }
  };

  const getTypeString = (type: TypeEnum): string => {
    switch (type) {
      case TypeEnum.Purchase:
        return t('registerType.purchased');
      case TypeEnum.Selling:
        return t('registerType.sold');
      case TypeEnum.Repair:
        return t('registerType.repaired');
      case TypeEnum.Dispose:
        return t(`registerType.${translationKeyDispose}`);
      default:
        return '';
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label='category table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('name')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>CO2</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('unit')}</TableCell>
              {/* <TableCell sx={{ fontWeight: 'bold' }}>Grupp ID</TableCell> */}
              <TableCell sx={{ fontWeight: 'bold' }}>{t('group')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('usedFor')}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {categories
              .sort((a, b) => a.categoryParentID - b.categoryParentID)
              .map((category) => {
                const types = category.type.map((type: number) => getTypeString(type)).join(', ');
                return (
                  <TableRow key={category.ID}>
                    <TableCell component='th' scope='row'>
                      {category.ID}
                    </TableCell>
                    <TableCell>{category.name}</TableCell>
                    <TableCell>{category.co2}</TableCell>
                    <TableCell>{category.unit}</TableCell>
                    {/* <TableCell>{category.categoryParentID}</TableCell> */}
                    <TableCell>{category.categoryParentName}</TableCell>
                    <TableCell>{types}</TableCell>
                    <TableCell>
                      <EditButton onClick={() => handleEdit(category)} />
                    </TableCell>
                    <TableCell>
                      <DeleteButton
                        onClick={() => {
                          setSelectedCategory(category);
                          setModalDeleteOpen(true);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedCategory && editOpen && (
        <EditCategoryModal onClose={() => setEditOpen(false)} modalOpen={editOpen} item={selectedCategory} />
      )}
      <GenericDialog
        open={modalDeleteOpen}
        onClose={() => setModalDeleteOpen(false)}
        onSave={handleOnDelete}
        dialogContent={
          <Box>
            <Typography>
              {t('confirmDeleteCategory')} {selectedCategory?.name}?
            </Typography>
          </Box>
        }
      />
    </>
  );
};

export default CategoryTable;
