import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField, Box } from '@mui/material';
// import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type ControlledDatePickerProps = {
  startDate: Date;
  onChangeStart: (e: any) => void;
  endDate: Date;
  onChangeEnd: (e: any) => void;
  disabled: boolean;
};

const DatePicker = (props: ControlledDatePickerProps) => {
  const { startDate, onChangeStart, endDate, onChangeEnd, disabled } = props;
  const { t } = useTranslation();

  // Eget state för att kunna öppna via custom end icon
  const [startOpen, setStartOpen] = useState(false);
  const [endOpen, setEndOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={sv}>
      <Box display='flex' mb={1}>
        <MobileDatePicker
          inputFormat='YYYY-MM-DD'
          mask='____-__-__'
          minDate='2020-01-01'
          disabled={disabled}
          value={startDate}
          open={startOpen}
          onOpen={() => setStartOpen(true)}
          onClose={() => setStartOpen(false)}
          onChange={(e) => {
            setStartOpen(false);
            onChangeStart(e);
          }}
          renderInput={(params) => (
            <TextField {...params} error={false} label={t('Start')} margin='dense' sx={{ mr: '20px' }} />
          )}
        />
        <MobileDatePicker
          inputFormat='YYYY-MM-DD'
          mask='____-__-__'
          minDate='2020-01-01'
          disabled={disabled}
          value={endDate}
          open={endOpen}
          onOpen={() => setEndOpen(true)}
          onClose={() => setEndOpen(false)}
          onChange={(e) => {
            setEndOpen(false);
            onChangeEnd(e);
          }}
          renderInput={(params) => <TextField {...params} error={false} label={t('Slut')} margin='dense' />}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DatePicker;
