import { createTheme } from '@mui/material/styles';
import shadows, { Shadows } from '@mui/material/styles/shadows';
import { Colors } from 'src/shared/styles/colors';

export const theme = createTheme({
  shadows: shadows.map(() => 'none') as Shadows,
  palette: {
    text: {
      primary: Colors.BLACK,
      secondary: Colors.STEEL,
    },
    primary: {
      main: Colors.GREEN,
    },
    secondary: {
      main: Colors.STEEL,
    },
    error: {
      main: Colors.RED,
    },
    warning: {
      main: Colors.ORANGE,
    },
    // info: {
    //   main: 'yellow',
    // },
    success: {
      main: Colors.GREEN,
    },
    background: {
      paper: Colors.WHITE,
      default: Colors.WHITE,
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h4: {
      fontSize: 22,
      fontWeight: 700,
      textTransform: 'none',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 700,
      textTransform: 'none',
    },
    button: {
      fontSize: 14,
      fontWeight: 700,
      textTransform: 'none',
    },
  },
  components: {
    // MuiCssBaseline: {
    //   styleOverrides: {
    //     body: {
    //       '& .MuiOutlinedInput-notchedOutline': {
    //         borderColor: Colors.WHITE,
    //       },
    //     },
    //   },
    // },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.6rem',
        },
      },
    },
  },
});
